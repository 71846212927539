import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
    projectId: 'asfbou62',
    dataset: 'production',
    apiVersion: '2022-02-01',
    useCdn: true,
    token: 'sknIjXAHH5upXqMUEhYB5K9PWp4xgTk2zx3QzqdTA4JGrSv2tUnCHIA4LskS1MCX4zaldjWW2hMyPTNF6UwVfJmr77jd45bd0tGqsuSrdcXCPac0NpUKZPJuquPoxE37cg5OPMbCNp532WmTS54xtXGPTU5BVhTpa2n4UOAkEU0niCeeVyrm',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);